<template>
    <div>
        <app-table
            class="devices-table"

            :cols="cols"
            :rows="devices"

            :clickable="true"
            :selected-row="rowUuid"
            :title="title"

            :sort-value="filter.sort"
            :sort-options="sort"
            @sort-change="onSortChange"

            @row-click="rowClick"
            @row-click-mobile="rowClickMobile"
        >
        </app-table>

        <app-pagination
            v-if="pagination.total"

            v-model="pagination.page"

            :total="pagination.total"
            :limit="pagination.limit"
            :page-range="pagination.range"

            @change="onPageChange"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

export default {
    components: {
        appLoader,
        appTable,
        appPagination,
    },
    props: {
        rowUuid: { type: String, default: null },
        title: { type: String, default: null },
        filterUuid: { type: String, default: null },
        currentPage: {type: Number, default: 1}
    },

    watch: {
        currentPage (val) {
            this.getDevices(val)
        }
    },

    created () {
        this.getDevices(this.currentPage)
    },

    data() {
        return {

            devices: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'CreatedAtNanos',
                    order: 'desc'
                },
            },

            sort: [
                { field: 'IMSI', title: 'IMSI' },
                { field: 'IMEI', title: 'IMEI' },
                // { field: 'CreatedAtNanos', title: 'Created at' },
            ],

            cols: [
                { key: 'IMSI',        title: 'IMSI',         sort: { field: 'IMSI'   }, highlight: true, },
                { key: 'IMEI',        title: 'IMEI',         sort: { field: 'IMEI'   }, highlight: true, },
                // { key: 'CreatedAtNanos',  title: 'Created at',   sort: { field: 'CreatedAtNanos' }, type: 'datenanos'},
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
        }
    },

    methods: {
        // init(to, from) {
        //     this.getDevices(to.params.page)
        // },

        getDevices(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            console.warn('filter', {...filter})

            let params = {
                "SPID": this.current_spid,
                'SubscriptionUUID': this.filterUuid,
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }
            console.warn("SPID", params.SPID)

            const word = filter.word.trim()
            console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }

            this.$store.dispatch('api_matrixx/GetMtxDevicesPaginated', params).then(response => {
                console.log('resp', response)
                this.devices = response.MtxDevices

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo
                console.log('DisplayPageList',DisplayPageList,'PageNumber', PageNumber, 'TotalItemCount', TotalItemCount)

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.devices = []

                this.loading = false
            })
        },


        /**
         * Filtering
         */
        onSearch(search) {
            if (search.word.trim() != this.filter.word.trim()) {
                this.getDevices(this.pagination.page, { word: search.word })
            }
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getDevices()
        },

        onPageChange(page) {
            this.getDevices(page)
        },

        rowClick(ev) {
            this.$emit('row-click', ev)
        },

        rowClickMobile(ev) {
            this.$emit('row-click-mobile', ev)
        }
    },

    computed: {
        ...mapGetters([
            'current_sp',
            'current_spid',

            'is_super_admin',
            'current_sp_roles',
            'available_service_providers',
        ])
    }
}
</script>
